<template>
<div :class="
    `v-treeview-node v-treeview-node--click ${
        hasChildren ? '' : 'v-treeview-node--leaf'
    }`
    ">
    <div class="v-treeview-node__root" @click="select">
        <div v-for="index in appendLevel" :key="index" class="v-treeview-node__level" />
        <i v-if="hasChildren" role="button" class="v-icon notranslate v-treeview-node__toggle v-icon--link mdi mdi-menu-down" :class="{
            'v-treeview-node__toggle--open': open,
            'theme--dark': isDark,
            'theme--light': !isDark
            }" />
        <slot name="prepend" v-bind="{ item: value, open }" />
        <div class="v-treeview-node__label">
            <slot name="label" v-bind="{ item: value, open }">
                {{ value.name }}
            </slot>
        </div>
        <slot name="append" v-bind="{ item: value }" />
    </div>
    <div v-if="open" class="v-treeview-node__children v-treeview-node__children__draggable">
        <draggable :group="group" :value="value[children]" ghost-class="ghost" @input="updateValue">
            <treeview-node v-for="(child, index) in value[children]" :children="children" :key="child[(itemKey ? itemKey : index)]" :item-key="itemKey" :group="group" :value="child" :level="level + 1" @input="updateChildValue" @click="click">
                <template v-slot:prepend="{ item, open }">
                    <slot name="prepend" v-bind="{ item, open }" />
                </template>
                <template v-slot:append="{ item }">
                    <slot name="append" v-bind="{ item }" />
                </template>
            </treeview-node>
        </draggable>
    </div>
</div>
</template>

<script>
import Vue, {
    PropType
} from "vue";
import Draggable from "vuedraggable";

export default Vue.extend({
    name: "TreeviewNode",
    components: {
        Draggable
    },
    props: {
        itemKey: { type: String, default: "" },
        children: { type: String, default: "children" },
        level: {
            type: Number,
            default: 0
        },
        value: {
            type: Object,
            default: {
                id: 0,
                name: "",
                children: []
            }
        },
        root: {
            type: Boolean,
            default: false
        },
        group: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            open: false,
            localValue: this.value
        };
    },
    computed: {
        hasChildren() {
            return this.value[this.children] != null && this.value[this.children].length > 0;
        },
        isDark() {
            return this.$vuetify.theme.isDark;
        },
        appendLevel() {
            return this.level + (this.hasChildren ? 0 : 1);
        }
    },
    watch: {
        value(value){
            this.localValue = value;
        }
    },
    methods: {
        select(){
            this.open = !this.open;
            this.$emit("click", this.localValue);
        },
        click(value){
            this.$emit("click", value);
        },
        updateValue(value) {
            this.localValue[this.children] = [...value];
            this.$emit("input", this.localValue);
        },
        updateChildValue(value) {
            const index = this.localValue[this.children].findIndex(c => c.id === value.id);
            this.$set(this.localValue[this.children], index, value);
            this.$emit("input", this.localValue);
        }
    }
});
</script>
