import axios from "@/plugins/axios";

export default {
    getOptions(_product, data){
        return axios.get(`/api/console/shop/products/${_product}/options`, data).then(result => result.data);
    },
    postOption(_product, data){
        return axios.post(`/api/console/shop/products/${_product}/options`, data).then(result => result.data);
    },
    putOption(_product, data){
        return axios.put(`/api/console/shop/products/${_product}/options/${data._id}`, data).then(result => result.data);
    },
    deleteOption(_product, data){
        return axios.delete(`/api/console/shop/products/${_product}/options/${data._id}`).then(result => result.data);
    }
}
