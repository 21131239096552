import axios from "@/plugins/axios";

let url = "/api/console/files";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
    post({ _brand, _category, _bandBanner, index }, file) {
        var headers = { "Content-Type": "multipart/form-data" };
        var formData = new FormData();

        if (_brand) formData.append("_brand", _brand);
        if (_category) formData.append("_category", _category);
        if (_bandBanner) formData.append("_bandBanner", _bandBanner);

        if (index !== undefined) formData.append("index", index);

        formData.append("file", file);

        return axios.post(`${url}`, formData, { headers }).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
