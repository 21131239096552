import Vue from "vue";
import VueRouter from "vue-router";
import axios from "@/plugins/axios";

import Main from "@/pages/client/Main.vue";
import { BoardRoutes } from "./board.router";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 공통 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // Terms 페이지
        path: "/terms/:code",
        component: () => import(/* webpackChunkName: "terms" */ "../pages/client/terms/TermsView.vue"),
        props: true,
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: "/shop",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/product-list.vue"),
        props: true,
    },
    {
        // 상품 검색결과
        path: "/shop/search",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/product-search.vue"),
        props: true,
    },
    {
        // 상품 뷰
        path: "/shop/products/:_product",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/product-view.vue"),
        props: true,
    },
    // {
    //     // 브랜드 목록
    //     path: "/shop/brand-list",
    //     component: () => import(/* webpackChunkName: "brand-market" */ "../pages/client/shop/brand-list.vue"),
    //     props: true,
    // },
    // {
    //     // 브랜드 마켓
    //     path: "/shop/brand-market/:brand",
    //     component: () => import(/* webpackChunkName: "brand-market" */ "../pages/client/shop/brand-market.vue"),
    //     props: true,
    // },
    {
        // 상품 - 기획전
        path: "/shop/exhibitions",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionList.vue"),
        props: true,
    },
    {
        // 상품 - 기획전 상세
        path: "/shop/exhibitions/:_exhibition",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionView.vue"),
        props: true,
    },
    {
        // 주문
        path: "/shop/order",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/order.vue"),
        props: true,
    },
    {
        // 주문완료
        path: "/shop/result",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/result.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // SNS 로그인 검증
        path: "/login/sns-validate",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/sns-validate.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/join.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage/dashboard",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 준회원페이지 - 단건 장바구니
        path: "/mypage/cart/single",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageSingleCartPage.vue"),
        props: () => ({ type: "cart" }),
    },
    {
        // 준회원페이지 - 정기 장바구니
        path: "/mypage/cart/regular",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRegularCartPage.vue"),
        props: () => ({ type: "regular" }),
    },
    {
        // 준회원페이지 - 주문내역
        path: "/mypage/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageOrdersPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 정기배송내역
        path: "/mypage/regular/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRegularOrdersPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 정기배송상세내역
        path: "/mypage/regular/orders/:_order",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRegularPurchasesPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 결제수단
        path: "/mypage/cards",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCardsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 쿠폰함
        path: "/mypage/coupon",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/coupon.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 찜한상품
        path: "/mypage/likes",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageLikesPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 적립금 내역
        path: "/mypage/points",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePointsPage.vue"),
        props: true,
    },
    {
        // 상품 문의
        path: "/mypage/inquiry",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/inquiry.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 회원 정보 변경
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 회원 정보 변경
        path: "/mypage/destinations",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDestinationsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 회원 탈퇴
        path: "/mypage/resign",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageResignPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 문의하기
        path: "/mypage/reviews",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReviewList.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 문의하기
        path: "/mypage/questions",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageQuestionsPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 대시보드
        path: "/promoter",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 대시보드
        path: "/promoter/dashboard",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 정회원페이지 - 보너스 내역
        path: "/promoter/bonuses",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 보너스 내역
        path: "/promoter/bonuses__:tab",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 보너스 출금
        path: "/promoter/bonuses-withdraw",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesWithdraw.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 추천회원 구매확정 내역
        path: "/promoter/promotee-purchases",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteePurchases.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/promoter-purchases",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoterPurchases.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/scores",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterScoresPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/bank",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBankPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 추천회원
        path: "/promoter/promotees",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 문의하기
        path: "/promoter/questions",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterQuestionsPage.vue"),
        props: true,
    },
    {
        // 회사소개
        path: "/center/about",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/about.vue"),
        props: true,
    },
    {
        // FAQ
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/center/privacy-policy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/privacy-policy.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/center/terms",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/terms.vue"),
        props: true,
    },
    {
        // 이메일무단수집거부
        path: "/center/email-policy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/email-policy.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/plugins/nice/pay/result",
        component: () => import("../pages/plugins/nice/NICEPayResult.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 -  회원직급
        path: "/console/user-grades",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-grades/UserGradeList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/user-levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원등급
        path: "/console/withdrawn-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/withdrawn-users/WithdrawnUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/inquiries/InquiryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 카테고리 관리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/categories/CategoryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 전시 관리
        path: "/console/shop/display",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/displays/ShopDisplayView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 전시 관리
        path: "/console/shop/exhibitions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/exhibitions/ShopExhibitionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 브랜드 관리
        path: "/console/shop/brands",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 브랜드 관리
        path: "/console/shop/brands/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 브랜드 관리
        path: "/console/shop/brands/:_brand",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산관리
        path: "/console/user-settlements",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산상세
        path: "/console/user-settlements/:_settlement",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 적립금 관리
        path: "/console/user-points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-points/UserPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 직급포인트 관리
        path: "/console/user-scores",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-scores/UserScoreList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 보너스 관리
        path: "/console/user-bonuses",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-bonuses/UserBonusList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 통계관리
        path: "/console/statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/StatisticsView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문관리
        path: "/console/shop/purchases/all",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PurchaseList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 미결제관리
        path: "/console/shop/purchases/nonpayment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/NonpaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 발주/발송관리
        path: "/console/shop/purchases/payment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배송관리
        path: "/console/shop/purchases/delivery",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/DeliveryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 취소관리
        path: "/console/shop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CancelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 반품관리
        path: "/console/shop/purchases/exchange",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ExchangeList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 교환관리
        path: "/console/shop/purchases/return",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ReturnList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 구매확정관리
        path: "/console/shop/purchases/complete",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CompleteList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 코드 관리
        path: "/console/shop/codes",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/codes/CodeList.vue"),
        props: true,
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/console/shop/coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponList.vue"),
        props: true,
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/console/shop/coupons/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/console/shop/coupons/:_coupon",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품문의
        path: "/console/shop/inquires",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/inquires/InquireList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 구매후기
        path: "/console/shop/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/reviews/ReviewList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 운영정보설정
        path: "/console/shop/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/setting/SettingView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notifications/NotificationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faqs/FaqList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/questions/QuestionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/trems/TermList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 띠배너관리
        path: "/console/band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popups/PopupList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
        scope: ["console"],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        }
        // else if (savedPosition) {
        //     scrollTo = savedPosition.y;
        // }

        if (scrollTo != undefined) {
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
        } else {
            return { x: 0, y: 0 }; // Go to the top of the page if no hash
        }
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => route.path == to.path);
        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근권한이 없습니다");

            next();
        } else next();
    } catch (error) {
        alert(error.message);
        let path;
        if (from.path != "/") path = routes.find((route) => route.path == from.path);
        if (!path) {
            path = ["/mypage", "promoter", "/console"].find((path) => to.path.includes(path)) || "/";
        }
        window.location.href = path;
    }
});
export default router;
