import axios from "@/plugins/axios";

let url = "/api/console/shop/brands";

export default {
    getBrands(data){
        return axios.get(url, data).then(result => result.data);
    },
    getBrand(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postBrand(data){
        return axios.post(url, data).then(result => result.data);
    },
    putBrand(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteBrand(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postImage(_brand, file){
        var formData = new FormData(); formData.append('image', file);
        var headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`${url}/${_brand}/image` , formData, { headers }).then(result => result.data);
    },
}
