import axios from "@/plugins/axios";

let url = "/api/v1/shop/regular-orders";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
	get(data) {
        return axios.get(`${url}/${data._id}`).then((result) => result.data);
    },
	put(data) {
		return axios.put(`${url}/${data._id}`, data).then((result) => result.data)
	},
    delete(data) {
        return axios.delete(`${url}/${data._id}`, data).then((result) => result.data)
    }
};
