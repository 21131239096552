<template>
    <div class="main-visual" v-if="banner">
        <swiper class="swiper" v-bind="{ options }">
            <swiper-slide v-for="(slide, index) in banner?.slides" :key="index">
                <v-card tile :to="slide?.isOuterLink ? undefined : slide?.url || undefined" :href="slide?.isOuterLink ? slide?.url || undefined : undefined" :target="slide?.target" class="main-visual__inner">
                    <div class="main-visual__img" :style="`background-image:url(${slide?.image});`">
                        <span class="d-sr-only">{{ slide?.meta?.title }}</span>
                    </div>
                </v-card>
            </swiper-slide>
            <div class="swiper-control">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const options = {
    effect: "fade",
    allowTouchMove: true,
    centeredSlides: true,
    spaceBetween: 0,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    speed: 500,
    loop: true,
    pagination: {
        el: ".main-visual .swiper-pagination",
        clickable: true,
    },
};

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        banner: null,

        detect,
        options,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { code } = this;
            const { banner } = await api.v1.banners.get({ code });
            console.log({ code, banner });
            this.banner = banner;
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        display: block;
        max-width: 1920px;
        width: 100%;
        height: 100%;
        margin: auto;
    }
    &__img {
        max-width: 100%;
        width: 100%;
        padding-top: calc(1180 / 750 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__img {
            padding-top: calc(580 / 1920 * 100%);
        }
    }
}
@media (min-width: 1024px) {
}
</style>
