<template>
    <client-page class="app--main">
        <!-- 메인 비주얼 -->
        <main-visual code="main-visual" class="d-none d-md-block" />
        <main-visual code="main-visual-mo" class="d-md-none" />

        <main-displays code="section1">
            <template #tit> 이달의 <span class="primary--text">핫딜 상품!</span> </template>
            <template #txt> 오늘 리본바이옴이 엄선한 가장 HOT한 상품! </template>
        </main-displays>

        <!-- 배너 -->
        <main-banner code="main-banner" class="d-none d-md-block" />
        <main-banner code="main-banner-mo" class="d-md-none" />

        <main-displays code="section2">
            <template #tit> 쇼핑의 재미 <span class="primary--text">생활의 발견!</span> </template>
            <template #txt> 오늘 리본바이옴이 엄선한 가장 HOT한 상품! </template>
        </main-displays>

        <main-displays code="section3" class="pt-0">
            <template #tit> MD <span class="primary--text">추천 상품!</span> </template>
            <template #txt> 오늘 리본바이옴이 엄선한 가장 HOT한 상품! </template>
        </main-displays>

        <main-displays code="section4" class="pt-0">
            <template #tit> 카테고리 <span class="primary--text">추천 상품!</span> </template>
            <template #txt> 오늘 리본바이옴이 엄선한 가장 HOT한 상품! </template>
        </main-displays>

        <popup-layer v-for="popup in popups" :key="popup._id" :popup="popup"></popup-layer>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainVisual from "@/components/client/main/main-visual.vue";
import MainBanner from "@/components/client/main/main-banner.vue";
import MainDisplays from "@/components/client/main/main-displays.vue";

export default {
    components: {
        ClientPage,
        PopupLayer,
        MainVisual,
        MainBanner,
        MainDisplays,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data: () => {
        return {
            popups: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getPopups();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPopups() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/popups");
                    this.popups = res.data.popups;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
