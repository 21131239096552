<template>
    <div class="main-section">
        <div class="container">
            <div v-if="this.$slots['tit']" class="tit-wrap text-center">
                <h2 class="tit tit--lg"><slot name="tit" /></h2>
                <p v-if="this.$slots['txt']" class="txt txt--lg line-height-135"><slot name="txt" /></p>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tit: String,
        txt: String,
    },
};
</script>
