import { BONUS_TYPES, INQUIRY_TYPES } from "./constants";

export const initDestination = (destination = {}) => ({
    ...destination,
    title: destination?.title || null,

    name: destination?.name || null,
    phone: destination?.phone || null,
    email: destination?.email || null,
    postcode: destination?.postcode || null,
    address1: destination?.address1 || null,
    address2: destination?.address2 || null,

    isDefault: destination?.isDefault || false,
});

export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    email: user?.email || null,
    phone: user?.phone || null,
    promoterCode: user?.promoterCode || null,
});

export const initBank = (bank = {}) => ({
    ...bank,
    code: bank?.code || null,
    accountNumber: bank?.accountNumber || null,
    accountHolder: bank?.accountHolder || null,
});

export const initPromoterApprovalInquiry = () => ({
    _terms: [],
    type: INQUIRY_TYPES.PROMOTER_APPROVAL.value,
    ci: null,
    phone: null,
    bank: initBank(),
    residentRegistrationNumber: null,
});

export const initOrderReceiver = ({ receiver: { name = null, phone = null, email = null, postcode = null, address1 = null, address2 = null } = {} } = {}) => ({
    name,
    phone,
    email,
    postcode,
    address1,
    address2,
});

export const initOrder = (order = {}) => ({
    ...order,
    receiver: initOrderReceiver(order),
    requestMessage: order?.requestMessage || null,
});

export const initPurchase = (purchase = {}) => ({
    ...purchase,
    claimStatus: purchase?.claimStatus || null,
    claimReason: purchase?.claimReason || null,
    claimReasonDetails: purchase?.claimReasonDetails || null,
});

export const initBonus__debit = (bonus = {}) => ({
    _certification: bonus?._certification || null,

    type: bonus?.type || BONUS_TYPES.BONUS__DEBIT.value,
    amount: bonus?.amount || 0,
    remain: bonus?.remain || 0,
    meta: {
        ...(bonus?.meta || {}),
        bank: initBank(bonus?.meta?.bank),
        charge: bonus?.meta?.charge || 0,
    },
});

export const initShopReview = (review = {}) => ({
    ...review,

    _product: review?._product || null,
    _purchase: review?._purchase || null,

    subject: review?.subject || null,
    content: review?.content || null,
    reply: review?.reply || null,
    star: review?.star || null,
    images: review?.images || [],
    product: review?.product || null,
});

export const initBankAction = (action = {}) => ({
    ...action,

    bank: initBank(action?.bank),
});

export const initBankHoldVerification = (verification = {}) => ({
    ...initBankAction(verification),

    sendAmount: verification?.sendAmount || null,
    senderName: verification?.senderName || null,
    attemptCount: verification?.attemptCount || 0,

    code: null,

    isSuccess: verification?.isSuccess || false,
    isOutDated: verification?.isOutDated || false,
});

export const initDataTableHeaders = (headers = []) => {
    return headers.map((item, index, array) => ({
        ...item,

        formatter: initFormatter(item),

        align: item.align ?? "start",
        divider: index != array.length - 1,

        class: (item.class || "") + " white-space-pre-line",
        cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line",
    }));
};

function initFormatter(header = {}) {
    if (header.formatter) return header.formatter;
    switch (header.formatterType) {
        case "date": {
            return (value) => value?.toDate?.() || value || "-";
        }
        case "datetime": {
            return (value) => value?.toDateTime?.() || value || "-";
        }
        case "number": {
            return (value) => ((value?.format?.() || value) + (header.suffix || "")).trim();
        }

        default: {
            return (value) => value ?? "-";
        }
    }
}
