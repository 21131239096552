<template>
    <header class="header">
        <!-- S: PC -->
        <div class="header__inner">
            <div class="header-head">
                <v-container>
                    <!-- <main-header-notice /> -->
                    <div class="divider-group">
                        <ul>
                            <li v-if="isPromoter">
                                <router-link to="/promoter" class="grey--text text--darken-4"><span>마이오피스</span></router-link>
                            </li>
                            <li v-if="!accessToken">
                                <router-link to="/login"><span>로그인</span></router-link>
                            </li>
                            <li v-else><a href="#" @click="logout">로그아웃</a></li>
                            <li v-if="!accessToken">
                                <router-link to="/join"><span>회원가입</span></router-link>
                            </li>
                            <li><router-link to="/center/faq">자주묻는 질문</router-link></li>
                            <li><a target="_blank" href="https://wellbingls.cafe24.com/WBLSkor">전자e카달로그</a></li>
                        </ul>
                    </div>
                </v-container>
            </div>

            <div class="header-body">
                <v-container>
                    <div class="header-contents">
                        <div class="logo">
                            <h1>
                                <router-link to="/" title="홈으로 이동"><span class="d-sr-only">리본바이옴</span></router-link>
                            </h1>
                        </div>
                        <div class="header-search">
                            <div class="header-search__form">
                                <input v-model="searchKey" name="searchKey" type="hidden" />
                                <input v-model="searchValue" class="header-search__input" name="searchValue" type="text" placeholder="검색어를 입력하세요" @keydown.enter="search" />
                                <button class="header-search__button" @click="search"><icon-search /></button>
                            </div>
                        </div>
                        <div class="header-user-menu">
                            <ul>
                                <!-- <li v-if="!accessToken">
                                    <router-link class="link" to="/mypage/order-list">
                                        <icon-person />
                                        <span class="header-user-menu__txt">비회원주문조회</span>
                                    </router-link>
                                </li> -->
                                <li v-if="accessToken">
                                    <router-link class="link" to="/mypage/dashboard">
                                        <icon-person />
                                        <span class="header-user-menu__txt">마이페이지</span>
                                    </router-link>
                                </li>
                                <li>
                                    <main-cart-button to="/mypage/cart/single">
                                        <template v-slot:default="{ carts }">
                                            <em class="badge">{{ carts.length }}</em>
                                            <icon-shopping-bag />
                                            <span class="header-user-menu__txt">장바구니</span>
                                        </template>
                                    </main-cart-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-container>
            </div>

            <div class="header-foot">
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="auto" class="flex-grow-1">
                            <v-row no-gutters class="flex-nowrap">
                                <v-col cols="auto">
                                    <div class="header-category">
                                        <button type="button" id="category" class="header-category__button">
                                            <span class="header-category__hamburger">
                                                <span class="header-category__hamburger-line"></span>
                                            </span>
                                            <span>전체 카테고리</span>
                                        </button>

                                        <!-- 카테고리 리스트 -->
                                        <div class="header-category__full">
                                            <main-shop-categories pc></main-shop-categories>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="auto" class="flex-grow-1">
                                    <div class="header-menu">
                                        <main-shop-categories menu>
                                            <li v-for="{ text, value } in groups" :key="value" class="header-menu__list">
                                                <router-link :to="{ path: '/shop/exhibitions', query: { group: value } }" class="header-menu__list-link">{{ text }}</router-link>
                                            </li>
                                            <li class="header-menu__list">
                                                <router-link to="/center/faq" class="header-menu__list-link">자주묻는 질문</router-link>
                                            </li>
                                        </main-shop-categories>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
        <!-- E: PC -->

        <!-- S: Mobile -->
        <!-- mobile-header -->
        <div class="mobile-header">
            <div class="logo">
                <h1>
                    <router-link to="/" title="홈으로 이동"><span class="d-sr-only">리본바이옴</span></router-link>
                </h1>
            </div>
            <!-- <ul class="mobile-header__customer">
                <li>
                    <router-link v-if="!accessToken" to="/login">로그인</router-link>
                    <a href="#" v-else @click="logout">로그아웃</a>
                </li>
                <li>
                    <router-link v-if="!accessToken" to="/mypage/order-list">비회원주문조회</router-link>
                    <router-link v-else to="/mypage/dashboard">마이페이지</router-link>
                </li>
            </ul> -->
            <button class="mobile-header__search"><icon-search /><span class="d-sr-only">상품검색창열기</span></button>
        </div>
        <!-- mobile-searchbar -->
        <div class="mobile-searchbar">
            <div class="mobile-searchbar__head">
                <button class="mobile-searchbar__close" type="button"><span class="d-sr-only">모바일 검색창 닫기</span></button>
                <div class="mobile-searchbar__form">
                    <input v-model="searchKey" name="searchKey" type="hidden" />
                    <input v-model="searchValue" class="mobile-searchbar__input" type="text" name="searchValue" placeholder="검색어를 입력하세요." @keydown.enter="search" />
                    <button class="mobile-searchbar__button" name="button" @click="search"><icon-search /><span class="d-sr-only">검색</span></button>
                </div>
            </div>
        </div>
        <!-- mobile-hamburger -->
        <div class="mobile-hamburger">
            <button type="button" aria-pressed="false" class="mobile-hamburger__btn">
                <span class="mobile-hamburger__line"></span>
            </button>
        </div>
        <!-- mobile-sidebar -->
        <div class="mobile-sidebar">
            <div class="mobile-sidebar-head">
                <button class="mobile-sidebar__close" type="button"><span class="d-sr-only">모바일 메뉴바 닫기</span></button>
                <div class="mobile-sidebar__info">
                    <router-link v-if="!accessToken" class="mobile-sidebar__member" to="/login">로그인 해주세요</router-link>
                    <a href="#" v-else @click="logout" class="mobile-sidebar__member">로그아웃</a>
                </div>
            </div>

            <div class="mobile-sidebar-body">
                <ul class="mobile-sidebar__mylink">
                    <li v-if="accessToken">
                        <!-- <router-link v-if="!accessToken" class="link" to="/mypage/order-list">
                            <icon-person />
                            <span>비회원주문조회</span>
                        </router-link> -->
                        <router-link class="link" to="/mypage/dashboard">
                            <icon-person />
                            <span>마이페이지</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/mypage/cart/single">
                            <icon-shopping-bag />
                            장바구니
                        </router-link>
                    </li>
                </ul>
                <ul class="mobile-sidebar__link">
                    <li v-for="{ text, value } in groups" :key="value">
                        <router-link class="link" :to="{ path: '/shop/exhibitions', query: { group: value } }">{{ text }} <i class="arrow"></i> </router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/center/faq">자주묻는 질문<i class="arrow"></i></router-link>
                    </li>
                    <!-- <li>
                        <router-link class="link" to="/shop?newly=true">신상품<i class="arrow"></i></router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/shop?best=true">BEST<i class="arrow"></i></router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/shop/brand-list">브랜드<i class="arrow"></i></router-link>
                    </li>
                    <li>
                        <router-link class="link" to="/">전자e카달로그<i class="arrow"></i></router-link>
                    </li> -->
                </ul>

                <main-shop-categories mobile></main-shop-categories>

                <a href="https://www.voxels.com/play?coords=SW@949E,208S" target="_blank" class="d-lg-none">
                    <v-img max-width="100%" src="/images/common/floating-meta-m.jpg" />
                </a>
                <router-link to="/center/about" class="d-lg-none">
                    <v-img max-width="100%" src="/images/common/floating-njob-m.jpg" />
                </router-link>
            </div>
        </div>
        <div class="mobile-header-dimmed"></div>
        <!-- E: Mobile -->
    </header>
</template>

<script>
import { EXHIBITION_GROUPS, USER_TYPES } from "@/assets/variables";

import IconSearch from "@/components/client/icons/icon-search.vue";
import IconPerson from "@/components/client/icons/icon-person.vue";
import IconShoppingBag from "@/components/client/icons/icon-shopping-bag.vue";

import MainCartButton from "../main/main-cart-button.vue";
import MainHeaderNotice from "./main-header-notice.vue";
import MainShopCategories from "../main/main-shop-categories.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        IconSearch,
        IconPerson,
        IconShoppingBag,

        MainCartButton,
        MainHeaderNotice,
        MainShopCategories,
    },
    data: () => ({
        searchKey: "name",
        searchValue: null,

        groups,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const { searchKey, searchValue } = this.$route?.query || {};
            this.searchKey = searchKey || "name";
            this.searchValue = searchValue;

            this.$nextTick(function() {
                this.header();
            });
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
        search() {
            this.$router.push(`/shop/search?searchKey=${this.searchKey}&searchValue=${this.searchValue}`);
        },

        // header
        header: function() {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function(e) {
                if (!ticking) {
                    window.requestAnimationFrame(function() {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function() {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                    hamburgerCreate();
                }
            }

            // Mobile Header
            let header = document.querySelector(".header"),
                hamburgerBtn = document.querySelector(".mobile-hamburger__btn"),
                searchBtn = document.querySelector(".mobile-header__search"),
                closeSidebar = document.querySelector(".mobile-sidebar__close"),
                closeSearchbar = document.querySelector(".mobile-searchbar__close"),
                dimmed = document.querySelector(".mobile-header-dimmed"),
                mobileCategory = document.querySelectorAll(".mobile-category__list-link[data-toggle]");

            hamburgerBtn.addEventListener("click", hamburgerDestroy);
            searchBtn.addEventListener("click", searchDestroy);
            closeSidebar.addEventListener("click", hamburgerCreate);
            closeSearchbar.addEventListener("click", searchCreate);
            dimmed.addEventListener("click", hamburgerCreate);
            dimmed.addEventListener("click", searchCreate);

            // Mobile Hamburger
            function hamburgerCreate() {
                let attr = header.getAttribute("data-dimmed");
                if (attr !== null) {
                    // HTML.removeAttribute("data-scroll");
                    hamburgerBtn.setAttribute("aria-pressed", "false");
                    header.setAttribute("data-sidebar", "closed");
                    header.setAttribute("data-dimmed", "closed");
                    mobileCategory.forEach((element) => {
                        element.setAttribute("data-toggle", "closed");
                    });
                }
            }
            function hamburgerDestroy() {
                // HTML.setAttribute("data-scroll", "false");
                hamburgerBtn.setAttribute("aria-pressed", "true");
                header.setAttribute("data-sidebar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
            // Mobile Search
            function searchCreate() {
                // HTML.removeAttribute("data-scroll");
                header.setAttribute("data-searchbar", "closed");
                header.setAttribute("data-dimmed", "closed");
            }
            function searchDestroy() {
                // HTML.setAttribute("data-scroll", "false");
                header.setAttribute("data-searchbar", "opened");
                header.setAttribute("data-dimmed", "opened");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        isPromoter() {
            return this.$store.state.payload?.userType == USER_TYPES.PROMOTER.value;
        },
    },
    watch: {
        "$route.query.searchKey"() {
            this.init();
        },
        "$route.query.searchValue"() {
            this.init();
        },
    },
};
</script>
