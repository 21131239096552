var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-person",
    attrs: {
      "viewBox": "0 0 21 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.5 0C7.77456 0 5.56516 2.2094 5.56516 4.93483C5.56516 7.66026 7.77456 9.86967 10.5 9.86967C13.2254 9.86967 15.4348 7.66026 15.4348 4.93483C15.4348 2.2094 13.2254 0 10.5 0ZM7.34795 4.93483C7.34795 3.19401 8.75917 1.78279 10.5 1.78279C12.2408 1.78279 13.652 3.19401 13.652 4.93483C13.652 6.67566 12.2408 8.08688 10.5 8.08688C8.75917 8.08688 7.34795 6.67566 7.34795 4.93483ZM10.5 11.1195C4.98316 11.1195 0.510864 15.5918 0.510864 21.1086V21.614C0.510864 21.8272 0.683675 22 0.896829 22H1.90769C2.12084 22 2.29365 21.8272 2.29365 21.614V21.1086C2.29365 16.5764 5.96777 12.9023 10.5 12.9023C15.0322 12.9023 18.7063 16.5764 18.7063 21.1086V21.614C18.7063 21.8272 18.8791 22 19.0923 22H20.1032C20.3163 22 20.4891 21.8272 20.4891 21.614V21.1086C20.4891 15.5918 16.0168 11.1195 10.5 11.1195Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }