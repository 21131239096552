<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header v-bind="{ disableScrollTop }"></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <slot name="subHead" />
            <slot name="contentsImmersive" />
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer></main-footer>
        <!-- E: FOOTER -->
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
export default {
    props: {
        disableScrollTop: { type: Boolean, default: false },
    },
    components: {
        MainHeader,
        MainFooter,
    },
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>
