import axios from "@/plugins/axios";

export default {
    postAutocomplete(_product, data){
        return axios.post(`/api/console/shop/products/${_product}/autocomplete`, data).then(result => result.data);
    },
    putAutocomplete(_product, data){
        return axios.put(`/api/console/shop/products/${_product}/autocomplete`, data).then(result => result.data);
    }
}
