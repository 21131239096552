import Vue from "vue";
import Vuex from "vuex";
import axios from "@/plugins/axios";

import api from "@/api";

import { bandBanners } from "./bandBanners";

Vue.use(Vuex);

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
var scope = payload?.scope || [];

var carts = JSON.parse(sessionStorage.getItem("carts") || "[]");
var pickup = JSON.parse(sessionStorage.getItem("pickup") || "[]");

var agreements = JSON.parse(sessionStorage.getItem("agreements") || "[]");

export default new Vuex.Store({
    state: {
        // user
        refreshToken,
        accessToken,
        payload,
        scope,
        user: undefined,

        // common
        likes: undefined,

        // shop
        carts,
        pickup,
        islands: [],
        shippings: [],
        categories: [],

        // join
        agreements,

        // ui
        main: { alert: true },
    },
    mutations: {
        // user
        agreements(state, agreements) {
            state.agreements = agreements;
            sessionStorage.setItem("agreements", JSON.stringify(agreements));
        },
        login(state, { refreshToken, accessToken }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split(".")[1] || "") || "{}");
            state.scope = state.payload?.scope || [];
            state.likes = [];

            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem("accessToken", accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];
            state.user = undefined;
            state.likes = undefined;
            state.carts = [];
            state.pickup = [];
            state.agreements = [];

            axios.defaults.headers.common["Authorization"] = "";

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("carts");
            sessionStorage.removeItem("pickup");
            sessionStorage.removeItem("agreements");
        },
        setUser(state, { user }) {
            state.user = user;
        },

        // common
        setLikes(state, { likes }) {
            state.likes = likes;
        },

        // shop
        setCarts(state, { carts }) {
            state.carts = carts;
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        addToCart(state, carts) {
            state.carts = [].concat(state.carts, carts);
            sessionStorage.setItem("carts", JSON.stringify(state.carts));
        },
        pickup(state, _carts) {
            state.pickup = _carts;
            sessionStorage.setItem("pickup", JSON.stringify(_carts));
        },
        setIslands(state, { islands }) {
            state.islands = islands;
        },
        setShippings(state, { shippings }) {
            state.shippings = shippings;
        },
        setCategories(state, { categories }) {
            state.categories = categories;
        },

        // ui
        hideMainAlert(state) {
            state.main.alert = false;
        },
    },
    actions: {
        // user
        async login({ dispatch, commit, state }, { username, password }) {
            const { refreshToken } = await api.auth.getRefreshToken({ username, password });
            const { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });

            await Promise.all(state.carts.map(async (cart) => await api.v1.me.carts.put(cart)));

            dispatch("getCarts");
        },
        async refresh({ commit }, refreshToken) {
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
        logout({ commit }) {
            commit("logout");
        },
        async getUser({ commit }) {
            const { user } = await api.v1.me.get();
            commit("setUser", { user });
        },
        agreements({ commit }, _terms) {
            commit("agreements", _terms);
        },

        // common
        async getLikes({ commit }) {
            const { likes } = await api.v1.me.likes.gets();
            commit("setLikes", { likes });
        },

        // shop
        addToCart({ commit }, carts) {
            commit("addToCart", carts);
        },
        pickup({ commit }, _carts) {
            commit("pickup", _carts);
        },
        async getCarts({ commit }) {
            const { carts } = await api.v1.me.carts.gets();
            commit("setCarts", { carts });
        },
        async getIslands({ commit }) {
            const { islands } = await api.v1.shop.islands.gets();
            commit("setIslands", { islands });
        },
        async getShippings({ commit }) {
            const { shippings } = await api.v1.shop.shippings.gets();
            commit("setShippings", { shippings });
        },
        async getCategories({ commit }) {
            const { categories } = await api.v1.shop.categories.gets();
            commit("setCategories", { categories });
        },
    },
    modules: { bandBanners },
});
