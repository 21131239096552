var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-banner"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('router-link', {
    staticClass: "main-banner__inner",
    attrs: {
      "to": "/shop"
    }
  }, [_c('div', {
    staticClass: "main-banner__img d-none d-md-block",
    staticStyle: {
      "background-image": "url(/images/main/main-banner.jpg)"
    }
  }), _c('div', {
    staticClass: "main-banner__img d-md-none",
    staticStyle: {
      "background-image": "url(/images/main/main-banner-mo.jpg)"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }