<template>
    <v-col :cols="cols" :sm="sm" :lg="lg" class="product-item">
        <div class="product-item__inner">
            <like-btn :params="{ _product: value?._id }">
                <template #activator="{ active, toggle }">
                    <v-btn icon small color="grey lighten-3" class="product-item__favorite" @click.stop.capture="toggle">
                        <span v-if="active" class="material-icons-outlined font-size-20 font-size-md-24 red--text">favorite</span>
                        <span v-else class="material-icons-outlined font-size-20 font-size-md-24 grey--text">favorite_border</span>
                    </v-btn>
                </template>
            </like-btn>
            <router-link :to="`/shop/products/${value._id}`">
                <div class="product-item__img" :style="`background-image:url(/res/shop/products/${value._id}/${value.thumb});`">
                    <div v-if="value.soldout || value.stock == 0" class="product-item__badge">Sold Out</div>
                </div>
                <div class="product-item__con">
                    <div class="product-item__tit">
                        <span>{{ value.name }}</span>
                    </div>
                    <div class="mt-10px mt-md-16px">
                        <span v-if="value.discountPrice" class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">{{ value.price.format() }}</span>
                        <em v-if="value.soldout || value.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                        <div v-else class="d-flex align-center font-size-18 font-size-lg-28 font-weight-bold">
                            <span v-if="value.discountRate" class="primary--text mr-4px mr-md-8px">{{ value.discountRate }}%</span>
                            <span class="d-flex align-center grey--text text--darken-4">{{ value.salePrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                        </div>
                    </div>
                    <div class="mt-10px mt-md-16px">
                        <div class="d-flex align-center">
                            <span class="material-icons-outlined primary--text">star</span>
                            <span class="font-size-14 font-weight-medium grey--text">{{ (value?.meta?.reviews?.star__average || 0).toFixed(1) }}</span>
                            <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ value?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
                        </div>
                        <div class="mt-4px mt-md-8px">
                            <v-row class="row--xxs">
                                <v-col v-if="value.newly" cols="auto"><v-chip small color="red lighten-5" text-color="red">신상품</v-chip></v-col>
                                <v-col v-if="value.best" cols="auto"><v-chip small color="light-blue lighten-5" text-color="light-blue">BEST</v-chip></v-col>
                                <v-col cols="auto"><v-chip small color="purple lighten-5" text-color="purple">당일배송</v-chip></v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </v-col>
</template>

<script>
import LikeBtn from "../like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },

        cols: { type: String, default: "6" },
        sm: { type: String, default: "4" },
        lg: { type: String, default: "3" },
    },
};
</script>
