import bandBanners from "./bandBanners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import me from "./me";
import promoter from "./promoter";
import setting from "./setting";
import shop from "./shop";
import terms from "./terms";
import users from "./users";

export default {
    bandBanners,
    banners,
    boards,
    center,
    me,
    promoter,
    setting,
    shop,
    terms,
    users,
};
