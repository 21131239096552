<template>
    <footer class="footer">
        <div class="footer-head">
            <v-container>
                <v-row align="center">
                    <v-col cols="auto">
                        <div class="divider-group divider-group--large">
                            <ul>
                                <li><router-link to="/center/about">회사·서비스소개</router-link></li>
                                <li><router-link to="/center/terms">이용약관</router-link></li>
                                <li><router-link to="/center/privacy-policy">개인정보처리방침 </router-link></li>
                                <li><router-link to="/center/notice">공지사항</router-link></li>
                            </ul>
                        </div>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-row>
                            <v-col cols="auto">
                                <v-btn outlined color="grey" class="w-100 min-w-md-120px" target="_blank" href="http://pf.kakao.com/_xemxlxcxj"><i class="icon icon-kch mr-8px" /> 카카오 채널</v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn outlined color="grey" class="w-100 min-w-md-120px" to="/mypage/questions">1:1 문의</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="footer-body">
            <v-container>
                <v-row align="center" justify="space-between" class="row--lg">
                    <v-col cols="12" lg="auto">
                        <v-row align="center">
                            <v-col cols="auto">
                                <div class="logo">
                                    <h1>
                                        <router-link to="/" title="홈으로 이동"><span class="d-sr-only">리본바이옴</span></router-link>
                                    </h1>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <!-- download="[(주)리본바이옴]에스크로 서비스 이용 확인증" -->
                                <v-btn x-large text target="_blank" href="/file/[(주)리본바이옴]에스크로 서비스 이용 확인증.pdf" class="v-btn--none-active-bg w-auto min-w-auto pa-0px">
                                    <v-img width="46" :aspect-ratio="46 / 56" src="/images/escrow-logo.png" />
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="auto">
                        <v-row class="row--lg">
                            <v-col cols="12" md="6" lg="auto" align-self="center">
                                <div class="inline-group">
                                    <ul>
                                        <li class="clear"><div class="font-size-16 font-weight-medium grey--text text--darken-4 mb-10px">주식회사 리본바이옴</div></li>
                                        <li class="clear"><span class="grey--text">대표이사 </span>이민섭</li>
                                        <li><span class="grey--text">사업자번호 </span>582-86-02805 <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5828602805" target="_blank" class="font-weight-bold">[사업자확인]</a></li>
                                        <li class="clear"><span class="grey--text">주소 </span>서울특별시 강서구 마곡중앙6로 66, 9층 907호(마곡동, 퀸즈파크텐)</li>
                                        <li class="clear"><span class="grey--text">이메일 </span>rebornbiome@naver.com</li>
                                        <li class="clear"><span class="grey--text">개인정보관리책임자 </span>이민섭</li>
                                        <li><span class="grey--text">통신판매업신고 </span>제2023-서울강서-1141호</li>
                                        <li class="clear"><span class="grey--text">건강기능식품판매업 </span>2022-0094150호</li>
                                        <li><span class="grey--text">방문판매업 </span>2023-서울강서-0004호</li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col cols="auto" class="d-none d-lg-block"><v-divider vertical class="h-100"/></v-col>
                            <v-col cols="12" md="6" lg="auto" align-self="center">
                                <div class="font-size-16 line-height-1 mb-10px mb-md-16px">
                                    <div class="font-weight-medium grey--text text--darken-4">대표전화</div>
                                    <div class="font-size-md-36 font-weight-bold mt-8px">02-6212-2825</div>
                                </div>
                                <div class="inline-group">
                                    <ul>
                                        <li><span class="grey--text">평일 </span>오전 09:00 ~ 오후 06:00</li>
                                        <li><span class="grey--text">점심 </span>오후 12:00 ~ 오후 01:00</li>
                                        <li><span class="grey--text">휴무 </span>토,일,공휴일</li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="footer-foot">
            <v-container>
                <div class="footer-copyright">
                    <p>당사의 모든 제작물의 저작권은 주식회사 리본바이옴에 있으며, 무단복제나 도용은 저작권법(97조5항)에 의해 금지되어 있습니다. 이를 위반 시 법적인 처벌을 받을 수 있습니다.</p>
                    <p>Copyright 2023. REBORN BIOME CO.,Ltd. All Rights Reserved.</p>
                </div>
            </v-container>
        </div>

        <div class="floating-menu">
            <a href="https://www.voxels.com/play?coords=SW@949E,208S" target="_blank" class="floating-menu__btn d-none d-lg-inline-flex">
                <v-img src="/images/common/floating-meta.png" />
            </a>
            <router-link to="/center/about" class="floating-menu__btn d-none d-lg-inline-flex">
                <v-img src="/images/common/floating-njob.png" />
            </router-link>
            <button class="floating-menu__btn floating-menu__scroll-top" @click="$vuetify.goTo(target, options)">
                <icon-arrow-rounded direction="up" class="grey--text text--darken-4" />
            </button>
        </div>
    </footer>
</template>

<script>
import IconArrowRounded from "../icons/icon-arrow-rounded.vue";
export default {
    components: {
        IconArrowRounded,
    },
    data: () => {
        return {
            carts: [],

            searchKey: "name",
            searchValue: null,

            // scrollTop
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            this.$nextTick(function() {
                this.footer();
            });
        },

        // footer
        footer: function() {
            const HTML = document.querySelector("html");
            let scrollPosition = 0,
                ticking = false;

            // Scroll Event Listener
            window.addEventListener("scroll", function(e) {
                if (!ticking) {
                    window.requestAnimationFrame(function() {
                        scrollAnimate();
                        ticking = false;
                    });
                    ticking = true;
                }
            });
            function scrollAnimate() {
                scrollPosition = window.scrollY || window.pageYOffset;
                // Scroll function 추가
                scrollTop();
            }
            scrollAnimate();

            // Resizing Event Listener
            window.addEventListener("resize", resizeThrottler, false);
            let mobileWidth = 1024;
            let resizeTimeout;
            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function() {
                        resizeTimeout = null;
                        actualResizeHandler();
                    }, 66);
                }
            }
            function actualResizeHandler() {
                let windowWidth = window.outerWidth;
                if (windowWidth > mobileWidth) {
                    // Resize function 추가
                }
                scrollAnimate();
            }

            // Scroll Top
            function scrollTop() {
                if (scrollPosition > window.innerHeight / 2) {
                    HTML.setAttribute("data-scroll-top", "true");
                } else {
                    HTML.removeAttribute("data-scroll-top");
                }
            }
        },
    },
    computed: {
        target: function() {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function() {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped></style>
