<template>
    <ul :class="{ 'header-category__lists': pc, 'mobile-category__lists': mobile, 'header-menu__lists': menu }">
        <li :class="{ 'header-category__list': pc, 'mobile-category__list': mobile, 'header-menu__list': menu }">
            <router-link to="/shop" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                <template v-if="pc">
                    <v-card outlined tile color="grey lighten-4" class="border-color mb-16px">
                        <v-responsive :aspect-ratio="180 / 80" content-class="d-flex">
                            <v-img max-width="102" src="/images/logo.svg" class="ma-auto" />
                        </v-responsive>
                    </v-card>
                    <span class="px-12px">전체 상품 보기</span>
                </template>
                <span v-else>전체상품</span>
            </router-link>
        </li>

        <li v-for="category in categories" :key="category?._id" :class="{ 'header-category__list': pc, 'mobile-category__list': mobile, 'header-menu__list': menu, 'header-menu__list-parents': menu && category.children.length != 0 }">
            <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
            </span>
            <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                <template v-if="pc">
                    <v-card v-if="category?.thumb?.src" outlined tile color="grey lighten-4" class="border-color mb-16px">
                        <v-img contain max-width="180" max-height="80" :aspect-ratio="180 / 80" :src="category?.thumb?.src" class="ma-auto" />
                    </v-card>
                    <span class="px-12px">{{ category.name }}</span>
                </template>
                <span v-else>{{ category.name }}</span>
                <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
            </router-link>

            <div v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--1': pc, 'mobile-category__level': mobile, 'mobile-category__level--1': mobile, 'header-menu__level': menu, 'header-menu__level--1': menu }">
                <div :class="{ 'header-category__level--1__inner': pc, 'header-menu__level--1__inner': menu }">
                    <div v-for="category in category.children" :key="category._id" :class="{ 'header-category__level-list': pc, 'mobile-category__level-list': mobile, 'header-menu__level-list': menu }">
                        <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                            <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                        </span>
                        <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                            <span>{{ category.name }}</span>
                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                        </router-link>

                        <ul v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--2': pc, 'mobile-category__level': mobile, 'mobile-category__level--2': mobile, 'header-menu__level': menu, 'header-menu__level--2': menu }">
                            <li v-for="category in category.children" :key="category._id">
                                <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                                    <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                                    <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                </span>
                                <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                                    <span>{{ category.name }}</span>
                                    <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                </router-link>

                                <ul v-if="category.children.length" :class="{ 'header-category__level': pc, 'header-category__level--3': pc, 'mobile-category__level': mobile, 'mobile-category__level--3': mobile, 'header-menu__level': menu, 'header-menu__level--3': menu }">
                                    <li v-for="category in category.children" :key="category._id">
                                        <span v-if="category.children.length && mobile" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }" :data-toggle="mobile ? 'closed' : ''">
                                            <router-link :to="`/shop?category=${category.code}`">{{ category.name }}</router-link>
                                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                        </span>
                                        <router-link v-else :to="`/shop?category=${category.code}`" :class="{ 'header-category__list-link': pc, 'mobile-category__list-link': mobile, 'header-menu__list-link': menu }">
                                            <span>{{ category.name }}</span>
                                            <v-icon v-if="category.children.length && mobile">mdi-chevron-down</v-icon>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="menu" :class="{ 'header-menu__logo': menu }">
                    <v-row>
                        <v-col cols="4">
                            <div class="header-menu__logo-img" style="background-image: url(/images/test-img.jpg)"></div>
                        </v-col>
                    </v-row>
                </div>
                <div v-if="menu" :class="{ 'header-menu__img': menu }">
                    <div class="header-menu__img-img" style="background-image: url(/images/test-img.jpg)"></div>
                </div>
            </div>
        </li>

        <slot />
    </ul>
</template>

<script>
export default {
    props: {
        pc: { type: Boolean, default: false },
        mobile: { type: Boolean, default: false },
        menu: { type: Boolean, default: false },
    },
    data: () => {
        return {
            categories: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getCategories();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        getOrder(category) {
            switch (category.code) {
                case "puppy":
                    return 2;
                case "cat":
                    return 3;
            }
        },
        getCategories() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/shop/categories");

                    this.categories = res.data.categories;
                    this.$nextTick(() => {
                        const HTML = document.querySelector("html");
                        var header = document.querySelector(".header");
                        var hamburgerBtn = document.querySelector(".mobile-hamburger__btn");
                        var mobileSidebarLink = document.querySelectorAll(".mobile-sidebar__link");
                        var mobileCategory = document.querySelectorAll(".mobile-category__list-link[data-toggle]");
                        var mobileCategoryLink = document.querySelectorAll("a.mobile-category__list-link");
                        var mobileCategoryLinkA = document.querySelectorAll("span.mobile-category__list-link >a");

                        mobileSidebarLink.forEach((element) => {
                            element.addEventListener("click", hamburgerCreate);
                        });
                        mobileCategory.forEach((element) => {
                            element.addEventListener("click", function () {
                                let state = this.getAttribute("data-toggle");
                                if (state === "closed") {
                                    this.setAttribute("data-toggle", "opened");
                                } else {
                                    this.setAttribute("data-toggle", "closed");
                                }
                            });
                        });
                        mobileCategoryLink.forEach((element) => {
                            element.addEventListener("click", hamburgerCreate);
                        });
                        mobileCategoryLinkA.forEach((element) => {
                            element.addEventListener("click", hamburgerCreate);
                        });

                        // Mobile Hamburger
                        function hamburgerCreate() {
                            let attr = header.getAttribute("data-dimmed");
                            if (attr !== null) {
                                hamburgerBtn.setAttribute("aria-pressed", "false");
                                header.setAttribute("data-sidebar", "closed");
                                header.setAttribute("data-dimmed", "closed");
                                mobileCategory.forEach((element) => {
                                    element.setAttribute("data-toggle", "closed");
                                });
                            }
                        }
                    });

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
