<template>
    <span> <slot name="activator" v-bind="{ active, toggle }" /> </span>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";

export default {
    props: {
        params: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState(["likes", "accessToken"]),
        like() {
            return (this.likes || []).find((item) => !Object.keys(this.params).some((key) => item[key] != this.params[key]));
        },
        active() {
            return !!this.like;
        },
    },
    methods: {
        ...mapMutations(["setLikes"]),
        async toggle() {
            console.log("isCalled?");
            if (!this.accessToken) {
                alert("로그인 후 사용하실 수 있습니다");
                return;
            }
            if (!!this.like) {
                await api.v1.me.likes.delete({ _id: this.like._id });
                this.setLikes({ likes: (this.likes || []).filter(({ _id }) => _id !== this.like._id) });
                this.$emit("removed");
            } else {
                const { like } = await api.v1.me.likes.post(this.params);
                this.setLikes({ likes: [...(this.likes || []), like] });
            }
        },
    },
};
</script>
