import axios from "@/plugins/axios";

let url = "/api/console/shop/categories";

export default {
    getCategories(data){
        return axios.get(url, data).then(result => result.data);
    },
    getCategory(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postCategory(data){
        return axios.post(url, data).then(result => result.data);
    },
    putCategory(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteCategory(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
