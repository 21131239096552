export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};

export const attrs_switch = {
    inset: true,
    hideDetails: "auto",
    class: "pa-0 ma-0 ml-1 mr-n4",
};

//  Client attrs preset
export const attrs_controls_common = {
    "hide-details": "auto",
};
export const attrs_select_common = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};