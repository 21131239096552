var render = function render(){
  var _vm$banner;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.banner ? _c('div', {
    staticClass: "main-visual"
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l((_vm$banner = _vm.banner) === null || _vm$banner === void 0 ? void 0 : _vm$banner.slides, function (slide, index) {
    var _slide$meta;
    return _c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      staticClass: "main-visual__inner",
      attrs: {
        "tile": "",
        "to": slide !== null && slide !== void 0 && slide.isOuterLink ? undefined : (slide === null || slide === void 0 ? void 0 : slide.url) || undefined,
        "href": slide !== null && slide !== void 0 && slide.isOuterLink ? (slide === null || slide === void 0 ? void 0 : slide.url) || undefined : undefined,
        "target": slide === null || slide === void 0 ? void 0 : slide.target
      }
    }, [_c('div', {
      staticClass: "main-visual__img",
      style: `background-image:url(${slide === null || slide === void 0 ? void 0 : slide.image});`
    }, [_c('span', {
      staticClass: "d-sr-only"
    }, [_vm._v(_vm._s(slide === null || slide === void 0 ? void 0 : (_slide$meta = slide.meta) === null || _slide$meta === void 0 ? void 0 : _slide$meta.title))])])])], 1);
  }), _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })])], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }