import bandBanners from "./band-banners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import files from "./files";
import inquiries from "./inquiries";
import levels from "./levels";
import popups from "./popups";
import setting from "./setting";
import settlements from "./settlements";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";

export default {
    bandBanners,
    banners,
    boards,
    center,
    files,
    inquiries,
    levels,
    popups,
    setting,
    settlements,
    shop,
    statistics,
    terms,
    users,
};
