import axios from "@/plugins/axios";

import cards from "./cards";
import carts from "./carts";
import destinations from "./destinations";
import inquiries from "./inquiries";
import likes from "./likes";
import orders from "./orders";
import points from "./points";
import purchases from "./purchases";
import reviews from "./reviews";

let url = "/api/v1/me";

export default {
    get() {
        return axios.get(`${url}/info`).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/info`, data).then((result) => result.data);
    },
    verify(data) {
        return axios.post(`${url}/verify`, data).then((result) => result.data);
    },
    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
    cards,
    carts,
    destinations,
    inquiries,
    likes,
    orders,
    points,
    purchases,
    reviews,
};
