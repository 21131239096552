var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    class: {
      'header-category__lists': _vm.pc,
      'mobile-category__lists': _vm.mobile,
      'header-menu__lists': _vm.menu
    }
  }, [_c('li', {
    class: {
      'header-category__list': _vm.pc,
      'mobile-category__list': _vm.mobile,
      'header-menu__list': _vm.menu
    }
  }, [_c('router-link', {
    class: {
      'header-category__list-link': _vm.pc,
      'mobile-category__list-link': _vm.mobile,
      'header-menu__list-link': _vm.menu
    },
    attrs: {
      "to": "/shop"
    }
  }, [_vm.pc ? [_c('v-card', {
    staticClass: "border-color mb-16px",
    attrs: {
      "outlined": "",
      "tile": "",
      "color": "grey lighten-4"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 180 / 80,
      "content-class": "d-flex"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "102",
      "src": "/images/logo.svg"
    }
  })], 1)], 1), _c('span', {
    staticClass: "px-12px"
  }, [_vm._v("전체 상품 보기")])] : _c('span', [_vm._v("전체상품")])], 2)], 1), _vm._l(_vm.categories, function (category) {
    var _category$thumb, _category$thumb2;
    return _c('li', {
      key: category === null || category === void 0 ? void 0 : category._id,
      class: {
        'header-category__list': _vm.pc,
        'mobile-category__list': _vm.mobile,
        'header-menu__list': _vm.menu,
        'header-menu__list-parents': _vm.menu && category.children.length != 0
      }
    }, [category.children.length && _vm.mobile ? _c('span', {
      class: {
        'header-category__list-link': _vm.pc,
        'mobile-category__list-link': _vm.mobile,
        'header-menu__list-link': _vm.menu
      },
      attrs: {
        "data-toggle": _vm.mobile ? 'closed' : ''
      }
    }, [_c('router-link', {
      attrs: {
        "to": `/shop?category=${category.code}`
      }
    }, [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1) : _c('router-link', {
      class: {
        'header-category__list-link': _vm.pc,
        'mobile-category__list-link': _vm.mobile,
        'header-menu__list-link': _vm.menu
      },
      attrs: {
        "to": `/shop?category=${category.code}`
      }
    }, [_vm.pc ? [category !== null && category !== void 0 && (_category$thumb = category.thumb) !== null && _category$thumb !== void 0 && _category$thumb.src ? _c('v-card', {
      staticClass: "border-color mb-16px",
      attrs: {
        "outlined": "",
        "tile": "",
        "color": "grey lighten-4"
      }
    }, [_c('v-img', {
      staticClass: "ma-auto",
      attrs: {
        "contain": "",
        "max-width": "180",
        "max-height": "80",
        "aspect-ratio": 180 / 80,
        "src": category === null || category === void 0 ? void 0 : (_category$thumb2 = category.thumb) === null || _category$thumb2 === void 0 ? void 0 : _category$thumb2.src
      }
    })], 1) : _vm._e(), _c('span', {
      staticClass: "px-12px"
    }, [_vm._v(_vm._s(category.name))])] : _c('span', [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 2), category.children.length ? _c('div', {
      class: {
        'header-category__level': _vm.pc,
        'header-category__level--1': _vm.pc,
        'mobile-category__level': _vm.mobile,
        'mobile-category__level--1': _vm.mobile,
        'header-menu__level': _vm.menu,
        'header-menu__level--1': _vm.menu
      }
    }, [_c('div', {
      class: {
        'header-category__level--1__inner': _vm.pc,
        'header-menu__level--1__inner': _vm.menu
      }
    }, _vm._l(category.children, function (category) {
      return _c('div', {
        key: category._id,
        class: {
          'header-category__level-list': _vm.pc,
          'mobile-category__level-list': _vm.mobile,
          'header-menu__level-list': _vm.menu
        }
      }, [category.children.length && _vm.mobile ? _c('span', {
        class: {
          'header-category__list-link': _vm.pc,
          'mobile-category__list-link': _vm.mobile,
          'header-menu__list-link': _vm.menu
        },
        attrs: {
          "data-toggle": _vm.mobile ? 'closed' : ''
        }
      }, [_c('router-link', {
        attrs: {
          "to": `/shop?category=${category.code}`
        }
      }, [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1) : _c('router-link', {
        class: {
          'header-category__list-link': _vm.pc,
          'mobile-category__list-link': _vm.mobile,
          'header-menu__list-link': _vm.menu
        },
        attrs: {
          "to": `/shop?category=${category.code}`
        }
      }, [_c('span', [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1), category.children.length ? _c('ul', {
        class: {
          'header-category__level': _vm.pc,
          'header-category__level--2': _vm.pc,
          'mobile-category__level': _vm.mobile,
          'mobile-category__level--2': _vm.mobile,
          'header-menu__level': _vm.menu,
          'header-menu__level--2': _vm.menu
        }
      }, _vm._l(category.children, function (category) {
        return _c('li', {
          key: category._id
        }, [category.children.length && _vm.mobile ? _c('span', {
          class: {
            'header-category__list-link': _vm.pc,
            'mobile-category__list-link': _vm.mobile,
            'header-menu__list-link': _vm.menu
          },
          attrs: {
            "data-toggle": _vm.mobile ? 'closed' : ''
          }
        }, [_c('router-link', {
          attrs: {
            "to": `/shop?category=${category.code}`
          }
        }, [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1) : _c('router-link', {
          class: {
            'header-category__list-link': _vm.pc,
            'mobile-category__list-link': _vm.mobile,
            'header-menu__list-link': _vm.menu
          },
          attrs: {
            "to": `/shop?category=${category.code}`
          }
        }, [_c('span', [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1), category.children.length ? _c('ul', {
          class: {
            'header-category__level': _vm.pc,
            'header-category__level--3': _vm.pc,
            'mobile-category__level': _vm.mobile,
            'mobile-category__level--3': _vm.mobile,
            'header-menu__level': _vm.menu,
            'header-menu__level--3': _vm.menu
          }
        }, _vm._l(category.children, function (category) {
          return _c('li', {
            key: category._id
          }, [category.children.length && _vm.mobile ? _c('span', {
            class: {
              'header-category__list-link': _vm.pc,
              'mobile-category__list-link': _vm.mobile,
              'header-menu__list-link': _vm.menu
            },
            attrs: {
              "data-toggle": _vm.mobile ? 'closed' : ''
            }
          }, [_c('router-link', {
            attrs: {
              "to": `/shop?category=${category.code}`
            }
          }, [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1) : _c('router-link', {
            class: {
              'header-category__list-link': _vm.pc,
              'mobile-category__list-link': _vm.mobile,
              'header-menu__list-link': _vm.menu
            },
            attrs: {
              "to": `/shop?category=${category.code}`
            }
          }, [_c('span', [_vm._v(_vm._s(category.name))]), category.children.length && _vm.mobile ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _vm._e()], 1)], 1);
        }), 0) : _vm._e()], 1);
      }), 0) : _vm._e()], 1);
    }), 0), _vm.menu ? _c('div', {
      class: {
        'header-menu__logo': _vm.menu
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('div', {
      staticClass: "header-menu__logo-img",
      staticStyle: {
        "background-image": "url(/images/test-img.jpg)"
      }
    })])], 1)], 1) : _vm._e(), _vm.menu ? _c('div', {
      class: {
        'header-menu__img': _vm.menu
      }
    }, [_c('div', {
      staticClass: "header-menu__img-img",
      staticStyle: {
        "background-image": "url(/images/test-img.jpg)"
      }
    })]) : _vm._e()]) : _vm._e()], 1);
  }), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }