<template>
    <main-section>
        <template slot="tit"> <slot name="tit" /> </template>
        <template slot="txt"> <slot name="txt" /> </template>

        <div class="main-products">
            <swiper class="swiper" v-bind="{ options }">
                <swiper-slide v-for="(product, index) in products" :key="index">
                    <v-row no-gutters>
                        <shop-product-item cols="12" sm="12" lg="12" :key="index" :value="product" />
                    </v-row>
                </swiper-slide>
                <div class="swiper-control" slot="pagination" @click.prevent>
                    <div class="swiper-button-prev" slot="button-prev"><v-icon>mdi-chevron-left</v-icon></div>
                    <div class="swiper-button-next" slot="button-next"><v-icon>mdi-chevron-right</v-icon></div>
                </div>
            </swiper>
        </div>
    </main-section>
</template>

<script>
import api from "@/api";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSection from "@/components/client/inc/main-section.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

const options = {
    allowTouchMove: true,
    autoplay: {
        delay: 5000,
        waitForTransition: true,
        disableOnInteraction: false,
    },
    breakpoints: {
        576: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 4,
        },
    },
    centeredSlides: false,
    lazy: {
        loadPrevNext: true,
    },
    loop: true,
    navigation: {
        nextEl: ".main-products .swiper-button-next",
        prevEl: ".main-products .swiper-button-prev",
    },
    slidesPerView: 2,
    spaceBetween: 24,
    speed: 500,
};

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSection,
        ShopProductItem,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        displays: [],
        options,
    }),
    computed: {
        products() {
            return this.displays.map(({ product } = {}) => product).filter((item) => !!item);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { displays } = await api.v1.shop.displays.gets({
                    params: this.$props,
                });
                this.displays = displays;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// main-section
.main-section {
    padding: 60px 0;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-section {
        padding: 80px 0;
    }
}
@media (min-width: 1024px) {
    .main-section {
        padding: 120px 0;
    }
}
@media (min-width: 1200px) {
}

// main-products
.main-products {
    .swiper-control {
        bottom: 68%;
        transform: translate(-50%, 50%);
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-products {
        &:hover {
            .swiper-control {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width: 1024px) {
}
</style>
