var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-shopping-bag",
    attrs: {
      "viewBox": "0 0 19 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.49991 0C7.12786 0 5.20493 1.92292 5.20493 4.29498V4.74155H2.98027C1.59023 4.74155 0.463379 5.8684 0.463379 7.25844V21.4831C0.463379 22.8731 1.59023 24 2.98027 24H16.0195C17.4096 24 18.5364 22.8731 18.5364 21.4831V7.25844C18.5364 5.8684 17.4096 4.74155 16.0195 4.74155H13.7949V4.29498C13.7949 1.92292 11.872 0 9.49991 0ZM12.3173 6.21917V9.53044C12.3173 9.6657 12.4269 9.77534 12.5622 9.77534H13.55C13.6852 9.77534 13.7949 9.6657 13.7949 9.53044V6.21917H16.0195C16.5935 6.21917 17.0588 6.68447 17.0588 7.25844V21.4831C17.0588 22.0571 16.5935 22.5224 16.0195 22.5224H2.98027C2.4063 22.5224 1.941 22.0571 1.941 21.4831V7.25844C1.941 6.68447 2.4063 6.21917 2.98027 6.21917H5.20493V9.53044C5.20493 9.6657 5.31457 9.77534 5.44983 9.77534H6.43765C6.57291 9.77534 6.68255 9.6657 6.68255 9.53044V6.21917H12.3173ZM6.68255 4.74155V4.29498C6.68255 2.73899 7.94393 1.47762 9.49991 1.47762C11.0559 1.47762 12.3173 2.73899 12.3173 4.29498V4.74155H6.68255Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }