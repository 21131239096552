import axios from "@/plugins/axios";

let url = "/api/console/levels";

export default {
    getLevels(data){
        return axios.get(url, data).then(result => result.data);
    },
    getLevel(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postLevel(data){
        return axios.post(url, data).then(result => result.data);
    },
    putLevel(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteLevel(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
