var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-head"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "divider-group divider-group--large"
  }, [_c('ul', [_c('li', [_c('router-link', {
    attrs: {
      "to": "/center/about"
    }
  }, [_vm._v("회사·서비스소개")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/terms"
    }
  }, [_vm._v("이용약관")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/privacy-policy"
    }
  }, [_vm._v("개인정보처리방침 ")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/notice"
    }
  }, [_vm._v("공지사항")])], 1)])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-md-120px",
    attrs: {
      "outlined": "",
      "color": "grey",
      "target": "_blank",
      "href": "http://pf.kakao.com/_xemxlxcxj"
    }
  }, [_c('i', {
    staticClass: "icon icon-kch mr-8px"
  }), _vm._v(" 카카오 채널")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-md-120px",
    attrs: {
      "outlined": "",
      "color": "grey",
      "to": "/mypage/questions"
    }
  }, [_vm._v("1:1 문의")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer-body"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("리본바이옴")])])], 1)])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--none-active-bg w-auto min-w-auto pa-0px",
    attrs: {
      "x-large": "",
      "text": "",
      "target": "_blank",
      "href": "/file/[(주)리본바이옴]에스크로 서비스 이용 확인증.pdf"
    }
  }, [_c('v-img', {
    attrs: {
      "width": "46",
      "aspect-ratio": 46 / 56,
      "src": "/images/escrow-logo.png"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', {
    staticClass: "clear"
  }, [_c('div', {
    staticClass: "font-size-16 font-weight-medium grey--text text--darken-4 mb-10px"
  }, [_vm._v("주식회사 리본바이옴")])]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("대표이사 ")]), _vm._v("이민섭")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("사업자번호 ")]), _vm._v("582-86-02805 "), _c('a', {
    staticClass: "font-weight-bold",
    attrs: {
      "href": "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5828602805",
      "target": "_blank"
    }
  }, [_vm._v("[사업자확인]")])]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("주소 ")]), _vm._v("서울특별시 강서구 마곡중앙6로 66, 9층 907호(마곡동, 퀸즈파크텐)")]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("이메일 ")]), _vm._v("rebornbiome@naver.com")]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("개인정보관리책임자 ")]), _vm._v("이민섭")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("통신판매업신고 ")]), _vm._v("제2023-서울강서-1141호")]), _c('li', {
    staticClass: "clear"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("건강기능식품판매업 ")]), _vm._v("2022-0094150호")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("방문판매업 ")]), _vm._v("2023-서울강서-0004호")])])])]), _c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "h-100",
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "auto",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "font-size-16 line-height-1 mb-10px mb-md-16px"
  }, [_c('div', {
    staticClass: "font-weight-medium grey--text text--darken-4"
  }, [_vm._v("대표전화")]), _c('div', {
    staticClass: "font-size-md-36 font-weight-bold mt-8px"
  }, [_vm._v("02-6212-2825")])]), _c('div', {
    staticClass: "inline-group"
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("평일 ")]), _vm._v("오전 09:00 ~ 오후 06:00")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("점심 ")]), _vm._v("오후 12:00 ~ 오후 01:00")]), _c('li', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("휴무 ")]), _vm._v("토,일,공휴일")])])])])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "footer-foot"
  }, [_c('v-container', [_c('div', {
    staticClass: "footer-copyright"
  }, [_c('p', [_vm._v("당사의 모든 제작물의 저작권은 주식회사 리본바이옴에 있으며, 무단복제나 도용은 저작권법(97조5항)에 의해 금지되어 있습니다. 이를 위반 시 법적인 처벌을 받을 수 있습니다.")]), _c('p', [_vm._v("Copyright 2023. REBORN BIOME CO.,Ltd. All Rights Reserved.")])])])], 1), _c('div', {
    staticClass: "floating-menu"
  }, [_c('a', {
    staticClass: "floating-menu__btn d-none d-lg-inline-flex",
    attrs: {
      "href": "https://www.voxels.com/play?coords=SW@949E,208S",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/common/floating-meta.png"
    }
  })], 1), _c('router-link', {
    staticClass: "floating-menu__btn d-none d-lg-inline-flex",
    attrs: {
      "to": "/center/about"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/common/floating-njob.png"
    }
  })], 1), _c('button', {
    staticClass: "floating-menu__btn floating-menu__scroll-top",
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(_vm.target, _vm.options);
      }
    }
  }, [_c('icon-arrow-rounded', {
    staticClass: "grey--text text--darken-4",
    attrs: {
      "direction": "up"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }