<template>
    <!-- <div class="main-banner">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="(slide, index) in banner?.slides" :key="index">
                <router-link :to="slide?.isOuterLink ? null : slide?.url" :href="slide?.isOuterLink ? slide?.url : null" :target="slide?.target" class="main-banner__inner">
                        <div class="main-banner__img" :style="'background-image:url(' + slide?.image + ');'">
                            <span class="d-sr-only">{{ slide?.meta?.title }}</span>
                        </div>
                    </router-link>
            </swiper-slide>
            <div v-if="index != 0" class="swiper-control">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </swiper>
    </div> -->
    <div class="main-banner">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide>
                <router-link to="/shop" class="main-banner__inner">
                    <!-- PC -->
                    <div class="main-banner__img d-none d-md-block" style="background-image:url(/images/main/main-banner.jpg);" />
                    <!-- Mobile -->
                    <div class="main-banner__img d-md-none" style="background-image:url(/images/main/main-banner-mo.jpg);" />
                </router-link>
            </swiper-slide>
            <div class="swiper-control">
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </swiper>
    </div>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            detect,
            // banner: null,
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: ".main-banner .swiper-pagination",
                    clickable: true,
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // let { banner } = await api.v1.banners.get({ code: this.$attrs.code });
            // this.banner = banner;
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-banner
.main-banner {
    width: 100%;
    &__inner {
        display: block;
        max-width: 1920px;
        width: 100%;
        height: 100%;
        margin: auto;
    }
    &__img {
        max-width: 100%;
        width: 100%;
        padding-top: calc(1180 / 750 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .swiper-control {
        bottom: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-banner {
        &__img {
            padding-top: calc(240 / 1920 * 100%);
        }
        .swiper-control {
            bottom: 26px;
        }
    }
}
@media (min-width: 1024px) {
}
</style>
