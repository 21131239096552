import axios from "@/plugins/axios";

let url = "/api/console/shop/shippings";

export default {
    getShippings(data){
        return axios.get(url, data).then(result => result.data);
    },
    // getShipping(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    // postShipping(data){
    //     return axios.post(url, data).then(result => result.data);
    // },
    putShipping(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    // deleteShipping(data){
    //     return axios.delete(`${url}/${data._id}`).then(result => result.data);
    // }
}
