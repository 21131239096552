import axios from "@/plugins/axios";

let url = "/api/console/users/points";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    // getPoint(data){
    //     return axios.get(`${url}/${data._id}`).then(result => result.data);
    // },
    postPoint(data){
        return axios.post(url, data).then(result => result.data);
    },
    // putPoint(data){
    //     return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    // },
    // deletePoint(data){
    //     return axios.delete(`${url}/${data._id}`).then(result => result.data);
    // }
}
