import axios from "@/plugins/axios";

let url = "/api/console/center/questions";

export default {
    getQuestions(data){
        return axios.get(url, data).then(result => result.data);
    },
    getQuestion(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postQuestion(data){
        return axios.post(url, data).then(result => result.data);
    },
    putQuestion(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteQuestion(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
