var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__inner"
  }, [_c('div', {
    staticClass: "header-head"
  }, [_c('v-container', [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_vm.isPromoter ? _c('li', [_c('router-link', {
    staticClass: "grey--text text--darken-4",
    attrs: {
      "to": "/promoter"
    }
  }, [_c('span', [_vm._v("마이오피스")])])], 1) : _vm._e(), !_vm.accessToken ? _c('li', [_c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_c('span', [_vm._v("로그인")])])], 1) : _c('li', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])]), !_vm.accessToken ? _c('li', [_c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_c('span', [_vm._v("회원가입")])])], 1) : _vm._e(), _c('li', [_c('router-link', {
    attrs: {
      "to": "/center/faq"
    }
  }, [_vm._v("자주묻는 질문")])], 1), _c('li', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://wellbingls.cafe24.com/WBLSkor"
    }
  }, [_vm._v("전자e카달로그")])])])])])], 1), _c('div', {
    staticClass: "header-body"
  }, [_c('v-container', [_c('div', {
    staticClass: "header-contents"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("리본바이옴")])])], 1)]), _c('div', {
    staticClass: "header-search"
  }, [_c('div', {
    staticClass: "header-search__form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchKey,
      expression: "searchKey"
    }],
    attrs: {
      "name": "searchKey",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.searchKey
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchKey = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "header-search__input",
    attrs: {
      "name": "searchValue",
      "type": "text",
      "placeholder": "검색어를 입력하세요"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "header-search__button",
    on: {
      "click": _vm.search
    }
  }, [_c('icon-search')], 1)])]), _c('div', {
    staticClass: "header-user-menu"
  }, [_c('ul', [_vm.accessToken ? _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_c('icon-person'), _c('span', {
    staticClass: "header-user-menu__txt"
  }, [_vm._v("마이페이지")])], 1)], 1) : _vm._e(), _c('li', [_c('main-cart-button', {
    attrs: {
      "to": "/mypage/cart/single"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var carts = _ref.carts;
        return [_c('em', {
          staticClass: "badge"
        }, [_vm._v(_vm._s(carts.length))]), _c('icon-shopping-bag'), _c('span', {
          staticClass: "header-user-menu__txt"
        }, [_vm._v("장바구니")])];
      }
    }])
  })], 1)])])])])], 1), _c('div', {
    staticClass: "header-foot"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "flex-nowrap",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-category"
  }, [_c('button', {
    staticClass: "header-category__button",
    attrs: {
      "type": "button",
      "id": "category"
    }
  }, [_c('span', {
    staticClass: "header-category__hamburger"
  }, [_c('span', {
    staticClass: "header-category__hamburger-line"
  })]), _c('span', [_vm._v("전체 카테고리")])]), _c('div', {
    staticClass: "header-category__full"
  }, [_c('main-shop-categories', {
    attrs: {
      "pc": ""
    }
  })], 1)])]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "header-menu"
  }, [_c('main-shop-categories', {
    attrs: {
      "menu": ""
    }
  }, [_vm._l(_vm.groups, function (_ref2) {
    var text = _ref2.text,
      value = _ref2.value;
    return _c('li', {
      key: value,
      staticClass: "header-menu__list"
    }, [_c('router-link', {
      staticClass: "header-menu__list-link",
      attrs: {
        "to": {
          path: '/shop/exhibitions',
          query: {
            group: value
          }
        }
      }
    }, [_vm._v(_vm._s(text))])], 1);
  }), _c('li', {
    staticClass: "header-menu__list"
  }, [_c('router-link', {
    staticClass: "header-menu__list-link",
    attrs: {
      "to": "/center/faq"
    }
  }, [_vm._v("자주묻는 질문")])], 1)], 2)], 1)])], 1)], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "mobile-header"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/",
      "title": "홈으로 이동"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("리본바이옴")])])], 1)]), _c('button', {
    staticClass: "mobile-header__search"
  }, [_c('icon-search'), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("상품검색창열기")])], 1)]), _c('div', {
    staticClass: "mobile-searchbar"
  }, [_c('div', {
    staticClass: "mobile-searchbar__head"
  }, [_vm._m(0), _c('div', {
    staticClass: "mobile-searchbar__form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchKey,
      expression: "searchKey"
    }],
    attrs: {
      "name": "searchKey",
      "type": "hidden"
    },
    domProps: {
      "value": _vm.searchKey
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchKey = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "mobile-searchbar__input",
    attrs: {
      "type": "text",
      "name": "searchValue",
      "placeholder": "검색어를 입력하세요."
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "mobile-searchbar__button",
    attrs: {
      "name": "button"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('icon-search'), _c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("검색")])], 1)])])]), _vm._m(1), _c('div', {
    staticClass: "mobile-sidebar"
  }, [_c('div', {
    staticClass: "mobile-sidebar-head"
  }, [_vm._m(2), _c('div', {
    staticClass: "mobile-sidebar__info"
  }, [!_vm.accessToken ? _c('router-link', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인 해주세요")]) : _c('a', {
    staticClass: "mobile-sidebar__member",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("로그아웃")])], 1)]), _c('div', {
    staticClass: "mobile-sidebar-body"
  }, [_c('ul', {
    staticClass: "mobile-sidebar__mylink"
  }, [_vm.accessToken ? _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/dashboard"
    }
  }, [_c('icon-person'), _c('span', [_vm._v("마이페이지")])], 1)], 1) : _vm._e(), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/mypage/cart/single"
    }
  }, [_c('icon-shopping-bag'), _vm._v(" 장바구니 ")], 1)], 1)]), _c('ul', {
    staticClass: "mobile-sidebar__link"
  }, [_vm._l(_vm.groups, function (_ref3) {
    var text = _ref3.text,
      value = _ref3.value;
    return _c('li', {
      key: value
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": {
          path: '/shop/exhibitions',
          query: {
            group: value
          }
        }
      }
    }, [_vm._v(_vm._s(text) + " "), _c('i', {
      staticClass: "arrow"
    })])], 1);
  }), _c('li', [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/center/faq"
    }
  }, [_vm._v("자주묻는 질문"), _c('i', {
    staticClass: "arrow"
  })])], 1)], 2), _c('main-shop-categories', {
    attrs: {
      "mobile": ""
    }
  }), _c('a', {
    staticClass: "d-lg-none",
    attrs: {
      "href": "https://www.voxels.com/play?coords=SW@949E,208S",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "100%",
      "src": "/images/common/floating-meta-m.jpg"
    }
  })], 1), _c('router-link', {
    staticClass: "d-lg-none",
    attrs: {
      "to": "/center/about"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "100%",
      "src": "/images/common/floating-njob-m.jpg"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mobile-header-dimmed"
  })]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "mobile-searchbar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 검색창 닫기")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-hamburger"
  }, [_c('button', {
    staticClass: "mobile-hamburger__btn",
    attrs: {
      "type": "button",
      "aria-pressed": "false"
    }
  }, [_c('span', {
    staticClass: "mobile-hamburger__line"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "mobile-sidebar__close",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v("모바일 메뉴바 닫기")])]);

}]

export { render, staticRenderFns }