var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--main"
  }, [_c('main-visual', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-visual"
    }
  }), _c('main-visual', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-visual-mo"
    }
  }), _c('main-displays', {
    attrs: {
      "code": "section1"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 이달의 "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("핫딜 상품!")])];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 오늘 리본바이옴이 엄선한 가장 HOT한 상품! ")];
      },
      proxy: true
    }])
  }), _c('main-banner', {
    staticClass: "d-none d-md-block",
    attrs: {
      "code": "main-banner"
    }
  }), _c('main-banner', {
    staticClass: "d-md-none",
    attrs: {
      "code": "main-banner-mo"
    }
  }), _c('main-displays', {
    attrs: {
      "code": "section2"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 쇼핑의 재미 "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("생활의 발견!")])];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 오늘 리본바이옴이 엄선한 가장 HOT한 상품! ")];
      },
      proxy: true
    }])
  }), _c('main-displays', {
    staticClass: "pt-0",
    attrs: {
      "code": "section3"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" MD "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("추천 상품!")])];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 오늘 리본바이옴이 엄선한 가장 HOT한 상품! ")];
      },
      proxy: true
    }])
  }), _c('main-displays', {
    staticClass: "pt-0",
    attrs: {
      "code": "section4"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 카테고리 "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("추천 상품!")])];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 오늘 리본바이옴이 엄선한 가장 HOT한 상품! ")];
      },
      proxy: true
    }])
  }), _vm._l(_vm.popups, function (popup) {
    return _c('popup-layer', {
      key: popup._id,
      attrs: {
        "popup": popup
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }