var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('template', {
    slot: "tit"
  }, [_vm._t("tit")], 2), _c('template', {
    slot: "txt"
  }, [_vm._t("txt")], 2), _c('div', {
    staticClass: "main-products"
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.products, function (product, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('shop-product-item', {
      key: index,
      attrs: {
        "cols": "12",
        "sm": "12",
        "lg": "12",
        "value": product
      }
    })], 1)], 1);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }